import type {
  IEmailTemplateContent,
  IEmailTemplateLanguageContent,
} from "@/api/types/email-template-types";
import type { ITranslatedData } from "@/api/types/email-types";
import type {
  IEmailContentModel,
  IEmailEditorModel,
  IEmailEditorResult,
  IEmailEditorRow,
} from "@/modules/email-editor/email-editor-types";
import type { Maybe } from "@/types/utility-types";

function editorRowHasContent(row: IEmailEditorRow) {
  return (row?.columns || []).some((c) => c.contents.length > 0);
}

/**
 * checks whether the editor has any content. No matter if it is text or just structures
 * @param value
 */
export function isEditorPristine(
  value: IEmailEditorResult | undefined
): boolean {
  if ((value?.body?.rows.length || 0) <= 0) {
    return true;
  }

  return false;
}

/**
 * returns true if the editor has no text content
 * @param value
 */
export function isEditorEmpty(value: IEmailEditorResult | undefined): boolean {
  if (
    value &&
    value.body &&
    value?.body?.rows.length > 0 &&
    value?.body?.rows.some((row) => editorRowHasContent(row))
  ) {
    return false;
  }

  return true;
}

export function isEditorContentSame(
  contentA?: Maybe<IEmailEditorResult>,
  contentB?: Maybe<IEmailEditorResult>
): boolean {
  return JSON.stringify(contentA) === JSON.stringify(contentB);
}

/**
 * returns an object where all languages are the keys and the default value is the value
 * @param languages
 * @param defaultValue
 */
export function getDefaultTranslatedResult<TData>(
  languages: string[],
  defaultValue: TData
): ITranslatedData<TData> {
  return languages.reduce((all, lang) => {
    return {
      ...all,
      [lang]: defaultValue,
    };
  }, {} as ITranslatedData<TData>);
}

/**
 * transforms lower case language keys to upper case language keys
 * @param data
 * @param convertFn
 */
export function convertToApiLanguages<TData = unknown, TOutput = TData>(
  data: ITranslatedData<TData>,
  convertFn?: (lang: string, apiLang: string) => TOutput
): ITranslatedData<TOutput> {
  const languages = Object.keys(data).map((lang) => lang.toLowerCase());

  if (!convertFn) {
    convertFn = (lang) => data[lang] as unknown as TOutput;
  }

  return languages.reduce((all, lang) => {
    const apiLang = lang.toUpperCase();
    return {
      ...all,
      [apiLang]: convertFn?.(lang, apiLang),
    } as ITranslatedData<TOutput>;
  }, {} as ITranslatedData<TOutput>);
}

/**
 * Converts the API response (only editor content) to the form model
 * @param apiResponse
 */
export function transformApiEditorResponse(apiResponse: {
  content: IEmailTemplateContent;
}): IEmailEditorModel {
  const languages = Object.keys(apiResponse.content || {}).map((lang) =>
    lang.toLowerCase()
  );

  return {
    html: languages.reduce((all, lang) => {
      return {
        ...all,
        [lang]: apiResponse.content[lang.toUpperCase()].html,
      };
    }, {}),
    json: languages.reduce((all, lang) => {
      return {
        ...all,
        [lang]: apiResponse.content[lang.toUpperCase()].editor_json,
      };
    }, {}),
  };
}

/**
 * Converts the API response (subject & editor content) to the form model
 * @param apiResponse
 */
export function transformApiContentResponse(apiResponse: {
  content: IEmailTemplateContent;
  subject: ITranslatedData<string>;
}): IEmailContentModel {
  return {
    ...transformApiEditorResponse(apiResponse),
    subject: Object.keys(apiResponse.subject || {}).reduce((all, lang) => {
      return {
        ...all,
        [lang.toLowerCase()]: apiResponse.subject?.[lang.toUpperCase()],
      };
    }, {}),
  };
}

/**
 * Converts editor content from the form model to the API
 * @param formModel
 */
export function transformFormModelEditorToApi(formModel: IEmailEditorModel): {
  content: IEmailTemplateContent;
} {
  return {
    content: convertToApiLanguages(formModel.json, (lang) => {
      return {
        html: formModel.html?.[lang],
        editor_json: formModel.json?.[lang],
      } as IEmailTemplateLanguageContent;
    }),
  };
}

/**
 * Converts editor content & subject from the form model to the API
 * @param formModel
 */
export function transformFormModelContentToApi(formModel: IEmailContentModel): {
  content: IEmailTemplateContent;
  subject: Record<string, string>;
} {
  return {
    ...transformFormModelEditorToApi(formModel),
    subject: convertToApiLanguages(formModel.subject),
  };
}

export function getDefaultFormModelEditor(
  languages: string[]
): IEmailEditorModel {
  return {
    json: getDefaultTranslatedResult<IEmailEditorResult | undefined>(
      languages,
      undefined
    ),
    html: getDefaultTranslatedResult<string>(languages, ""),
  };
}

export function getDefaultFormModelContent(
  languages: string[]
): IEmailContentModel {
  return {
    ...getDefaultFormModelEditor(languages),
    subject: getDefaultTranslatedResult<string>(languages, ""),
  };
}
