<template>
  <VueQueryDevtools />
  <div class="kinn-app text-app-black">
    <transition name="fade">
      <DialogsRoot>
        <v-app>
          <template v-if="!loading && !isLoading && !setupStore.isLoading">
            <BlankLayout v-if="isBlankLayout" />
            <DefaultLayout v-else-if="isAuthenticated" />
            <PublicLayout v-else />
          </template>
          <template v-else>
            <AppSPASkeleton />
          </template>
          <TeleportTarget :name="teleportTargets.body" />
          <AppSnackbar />
        </v-app>
      </DialogsRoot>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useIntercom } from "@/composables/use-intercom";
import { useVueQueryCleanup } from "@/composables/use-vue-query-cleanup";
import { routeNames } from "@/router/route-names";

import { authService } from "./api/services/auth-service";
import AppSPASkeleton from "./components/skeletons/AppSPASkeleton.vue";
import { useRemoteTranslations } from "./composables/use-remote-translations";
import BlankLayout from "./layouts/BlankLayout.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import PublicLayout from "./layouts/PublicLayout.vue";
import AppSnackbar from "./modules/snackbar/components/AppSnackbar.vue";
import TeleportTarget from "./modules/teleports/components/TeleportTarget.vue";
import { teleportTargets } from "./modules/teleports/teleports.config";
import DialogsRoot from "./plugins/dialogs/DialogsRoot.vue";
import { useAuthStore } from "./store/auth-store";
import { usePermissionStore } from "./store/permission-store";
import { useSetupStore } from "./store/setup-store";
import { useUserSettingsStore } from "./store/user-settings-store";

// keep this initialization here!
useUserSettingsStore();

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const permissionStore = usePermissionStore();
const setupStore = useSetupStore();
const { isLoading } = useRemoteTranslations();
useVueQueryCleanup();

const loading = ref(true);
const isAuthenticated = computed(() => {
  return authStore.isAuthenticated && permissionStore.permissions;
});

const layout = computed(() => route.meta?.layout);
const isBlankLayout = computed(() => layout.value === "blank");

useIntercom();

onMounted(() => {
  authService.initializeSession().finally(() => {
    loading.value = false;
  });
});

onMounted(() => {
  authStore.$onAction((action) => {
    if (action.name === "logout" && route.name !== routeNames.login) {
      router.push({ name: routeNames.login }).catch(() => {
        // Catch but ignore. This is fine!
        // We need to redirect the user here because we should not use the router in states.
      });
    }
  });
});

router.onError((error, to) => {
  const errors = [
    "Failed to fetch dynamically imported module",
    "Importing a module script failed",
  ];
  if (errors.some((e) => error.message.includes(e))) {
    window.location.href = to.fullPath;
  }
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
