<template>
  <div data-test-id="login">
    <UICard
      v-show="showLogin"
      class="max-w-full w-[400px] min-w-0"
      data-test-id="login-form-card"
    >
      <UICardHeader>
        <img alt="Kinnovis" class="img-fluid w-60 mb-6" :src="logo" />
        <UICardTitle>{{ $t("loginForm.title") }}</UICardTitle>
      </UICardHeader>
      <UICardBody>
        <v-form
          ref="form"
          v-model="isFormValid"
          data-test-id="login-form"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="formModel.email.value"
            v-bind="formModel.email.bindings"
            name="email"
            autocomplete="email"
            data-test-id="email-input"
          />

          <v-text-field
            v-model="formModel.password.value"
            v-bind="passwordBindings"
            name="password"
            autocomplete="current-password"
            data-test-id="password-input"
          />

          <UIButton
            :disabled="!isFormValid"
            :loading="loading"
            name="login"
            type="submit"
            class="block w-full mt-8"
            data-test-id="submit-button"
            >{{ $t("loginForm.login") }}
          </UIButton>
        </v-form>
      </UICardBody>
    </UICard>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { type RouteLocationRaw, useRouter } from "vue-router";

import { authService } from "@/api/services/auth-service";
import logo from "@/assets/logos/kinnovis_logo.png";
import { usePasswordField } from "@/composables/use-password-field";
import { useTranslate } from "@/composables/use-translate";
import { useNotifications } from "@/modules/snackbar/composables/use-notifications";
import UIButton from "@/modules/ui/buttons/components/UIButton.vue";
import UICard from "@/modules/ui-card/components/UICard.vue";
import UICardBody from "@/modules/ui-card/components/UICardBody.vue";
import UICardHeader from "@/modules/ui-card/components/UICardHeader.vue";
import UICardTitle from "@/modules/ui-card/components/UICardTitle.vue";
import { defaultLandingPage } from "@/router";
import { useAuthStore } from "@/store/auth-store";
import { useUserSettingsStore } from "@/store/user-settings-store";
import type { IFormReactive } from "@/types/form-types";
import type { IVFormExpose } from "@/types/vuetify-types";
import { useFormField } from "@/utils/form-utils";
import { emailFn, requiredFn } from "@/utils/validation-rules-utils";

const router = useRouter();
const { t } = useTranslate();

// Keep initialization here
useUserSettingsStore();

const authStore = useAuthStore();

const { showError } = useNotifications();

interface IFormModel {
  email: string;
  password: string;
}

const form = ref<IVFormExpose>();
const routerTarget: RouteLocationRaw = { name: defaultLandingPage };

const formModel: IFormReactive<IFormModel> = reactive({
  email: useFormField(t("loginForm.email"), "", [requiredFn, emailFn]),
  password: useFormField(t("loginForm.password"), "", [requiredFn]),
});

const loading = ref(false);
const isFormValid = ref(true);
const { props: passwordFieldProps } = usePasswordField();
const showLogin = computed(() => !authStore.isAuthenticated);

const passwordBindings = computed(() => {
  return {
    ...formModel.password.bindings,
    ...passwordFieldProps.value,
  };
});

async function onSubmit() {
  const validation = await form.value?.validate();
  if (validation?.valid) {
    loading.value = true;

    return authService
      .login({
        email: formModel.email.value,
        password: formModel.password.value,
      })
      .then(() => {
        if (!authStore.user) {
          showError(t("validation.credentials.incorrect"));
          throw new Error("Authorization failed");
        }

        const lastRouterState = authStore.lastRoute;

        if (lastRouterState) {
          authStore.lastRoute = "";

          return router.push(
            lastRouterState === "/login" || lastRouterState === "/forbidden"
              ? routerTarget
              : (lastRouterState as string)
          );
        } else {
          return router.push(routerTarget);
        }
      })
      .catch(() => {
        showError(t("validation.credentials.incorrect"));
      })
      .finally(() => {
        loading.value = false;
      });
  }
}
</script>

<style scoped lang="scss">
:deep(.v-card-title) {
  font-size: 1.25rem !important;
}
</style>
