import { groupBy } from "lodash-es";

export const routeNames = {
  dashboardOverview: "analytics",

  bookingOverview: "bookings",
  bookingCreate: "booking-create",
  bookingDetail: "booking-item",
  bookingAdjust: "booking-adjust",
  bookingEditGeneral: "booking-edit-general",

  customerOverview: "customers",
  customerCreate: "customer-create",
  customerDetails: "customer-item",
  customerEditGeneral: "customer-edit-general",
  customerEditDetails: "customer-edit-details",

  invoiceOverview: "invoices",
  invoiceCreate: "invoice-create",
  invoiceDetails: "invoice-item",
  invoiceIssueCreditNote: "invoice-issue-credit-note",

  accountingExportBmd: "accounting-export-bmd",
  accountingExportXero: "accounting-export-xero",
  accountingExportPdfExport: "accounting-export-pdf-export",

  unitOverview: "units",
  unitCreate: "unit-create",
  unitDetails: "unit-item",
  unitEditGeneral: "unit-edit-general",
  unitEditMeasurements: "unit-edit-measurements",

  discountOverview: "discounts",
  discountEditGeneral: "discount-edit-general",
  discountEditCode: "discount-code-edit",
  discountCreate: "discount-create",
  discountDetails: "discount-details",

  tenantsOverview: "tenants",
  tenantDetails: "tenant-item",
  tenantCreate: "tenant-create",
  tenantEditGeneral: "tenant-edit-general",
  tenantModifyRanges: "tenant-modify-ranges",
  tenantModifyLocations: "tenant-modify-locations",
  tenantModifyUsers: "tenant-modify-users",

  locationOverview: "locations",
  locationDetails: "location-item",
  locationEditGeneral: "location-edit-general",
  locationEditUnitSettings: "location-edit-unit-settings",
  locationEditBilling: "location-edit-billing",
  locationEditFloors: "location-edit-floors",
  locationEditContracts: "location-edit-contracts",

  unitTypeOverview: "unit-types",
  unitTypeCreate: "unit-type-create",
  unitTypeDetails: "unit-type-item",
  unitTypeEditGeneral: "unit-type-edit-general",
  unitTypeEditStorefront: "unit-type-edit-storefront",
  unitTypeEditTax: "unit-type-edit-tax",
  unitTypeAddBookingPlan: "unit-type-add-booking-plan",
  unitTypeEditBookingPlan: "unit-type-edit-booking-plan",

  insuranceOverview: "insurances",
  insuranceCreate: "insurance-create",
  insuranceDetails: "insurance-item",
  insuranceEditGeneral: "insurance-edit-general",
  insuranceEditStorefront: "insurance-edit-storefront",
  insuranceEditTax: "insurance-edit-tax",
  insuranceAddBookingPlan: "insurance-add-booking-plan",

  productOverview: "products",
  productCreate: "product-create",
  productDetails: "product-item",
  productEditGeneral: "product-edit-general",
  productEditTax: "product-edit-tax",

  emailOverview: "emails",
  emailDetails: "email-detail",
  emailEdit: "email-edit",
  emailDuplicate: "email-duplicate",
  emailCreate: "email-create",
  emailDuplicateList: "email-duplicate-list",

  emailDomainOverview: "email-domains",
  emailDomainCreate: "email-domain-create",
  emailDomainVerify: "email-domain-verify",
  emailDomainEdit: "email-domain-edit",

  emailTemplateOverview: "email-templates",
  emailTemplateCreate: "email-template-create",
  emailTemplateEdit: "email-template-edit",

  connectedApps: "connectedApps",
  connectedAppsSensorberg: "sensorberg-connect",
  connectedAppsSCSolutions: "sc_solutions-connect",
  connectedAppsNoke: "noke-connect",
  connectedAppsSedisto: "sedisto-connect",
  connectedAppsSignable: "signable-connect",
  connectedAppsSignableHowToSetup: "signable-connect-how-to-setup",
  connectedAppsStripeIdCheck: "stripe_id_check-connect",
  connectedAppsOpenTech: "open_tech-connect",

  userOverview: "users",
  userCreate: "user-create",
  userDetails: "user-item",
  userEditGeneral: "user-edit-general",

  locationSettingsOverview: "location-settings",
  locationSettingsDetails: "location-settings-item",
  locationSettingsEditGeneral: "location-settings-edit-general",
  locationSettingsEditBilling: "location-settings-edit-billing",
  locationSettingsEditCategories: "location-settings-edit-categories",
  locationSettingsEditConsents: "location-settings-edit-consents",
  locationSettingsEditCustomerPortal: "location-settings-edit-customer-portal",
  locationSettingsEditContact: "location-settings-edit-contact",
  locationSettingsEditFacilityOpeningHours:
    "location-settings-edit-opening-self-storage",
  locationSettingsEditOfficeOpeningHours:
    "location-settings-edit-opening-office",
  locationSettingsEditSignature: "location-settings-edit-signature",

  globalSettings: "global-settings",
  globalSettingsEditColors: "color-palette-edit",
  globalSettingsEditGeneral: "global-settings-general-edit",
  globalSettingsEditMarketing: "global-settings-marketing-edit",

  pdfRendererReport: "pdf-renderer-report",
  pdfRendererReportTest: "pdf-renderer-report-test",
  pdfRendererReportTestRenderer: "pdf-renderer-report-renderer",

  login: "login",
  maintenance: "maintenance",
  votingPortal: "votingPortal",
  forbidden: "forbidden",
  notFound: "notfound",

  facilityMapOverview: "facility-map-overview",
  facilityMapUnitSidebar: "facility-map-unit-sidebar",
  facilityMapEditor: "facility-map-editor",
  facilityMapEditorCreateBooking: "facility-map-editor-create-booking",
  facilityMapEditorAdjustBooking: "facility-map-editor-adjust-booking",

  tasksOverview: "tasks-overview",
  taskCreate: "task-create",
  taskEdit: "task-edit",
  taskMailRedirect: "task-mail-redirect",
  tasksNotificationSettings: "tasks-notification-settings",

  pdfReportsDownload: "pdf-report-download",
};

// Print warning if names are not unique:
const names = groupBy(Object.values(routeNames));

for (const name of Object.values(names)) {
  if (name.length > 1) {
    console.warn(`🚨 Route name "${name[0]}" appears ${name.length} times!`);
  }
}
