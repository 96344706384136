import { isEqual, isString, set } from "lodash-es";
import {
  type ComputedRef,
  type MaybeRef,
  type MaybeRefOrGetter,
  type Ref,
  toValue,
} from "vue";
import { computed, isRef, ref, shallowRef, unref, watch } from "vue";

import type {
  IApiError,
  IAPIErrorDetails,
  ISimpleAxiosError,
} from "@/api/types/api-error-types";
import { useTranslate } from "@/composables/use-translate";
import type { IForm, IFormField, IFormReactive } from "@/types/form-types";
import type { Maybe } from "@/types/utility-types";
import type { ValidationFnFactory } from "@/utils/validation-rules-utils";
import { requiredLabel } from "@/utils/validation-rules-utils";

import { toArray } from "./array-utils";

export type IFormFieldApiName = (string | RegExp)[] | undefined;

export function useFormErrors<T extends IForm<R>, R>(
  form: T,
  errorResponse: Ref<Maybe<ISimpleAxiosError<IApiError>>>
): T {
  const errors = computed(() => errorResponse.value?.response?.data.error);

  watch(
    [errorResponse],
    () => {
      const fields = Object.keys(form) as (keyof T)[];

      fields.forEach((field) => {
        const names = (unref(form[field]?.responseName) ||
          unref(field)) as IFormFieldApiName;

        const error = names
          ? errors.value?.details.find((d) => {
              return matchesName(names, d.field);
            })
          : undefined;

        if (error) {
          form[field].setError(error);
        } else {
          form[field].clearError();
        }
      });
    }
    // This breaks the connected app forms :(
    // { immediate: true }
  );

  return form;
}

export function useFormErrorsArray<T>(
  givenFormFields: MaybeRef<IFormField<T>[]>,
  errorResponse: MaybeRefOrGetter<
    ISimpleAxiosError<IApiError> | undefined | null
  >
) {
  const formFields = shallowRef(givenFormFields);
  const errors = computed(() => toValue(errorResponse)?.response?.data.error);

  watch(
    [errorResponse],
    () => {
      formFields.value.forEach((field) => {
        const names = unref(field.responseName.value || field.responseName);
        const error = errors.value?.details.find((d) =>
          matchesName(names, d.field)
        );

        if (error) {
          field.setError(error);
        } else {
          field.clearError();
        }
      });
    },
    { immediate: true }
  );
}

export function useFormField<
  T,
  TProps extends object = Record<string, unknown>,
>(
  givenLabel: MaybeRef<string>,
  initialValue: T,
  givenRules?: MaybeRef<ValidationFnFactory[]>,
  givenResponseName?: MaybeRef<string | RegExp | IFormFieldApiName>
): IFormField<T, TProps> {
  const value = ref(initialValue) as Ref<T>;
  const label = ref(givenLabel);
  const responseName = ref(givenResponseName);
  const rules = ref(givenRules);
  const error = ref<IAPIErrorDetails | undefined>(undefined);
  const additionalProps = ref<undefined | TProps>(undefined);
  const { t } = useTranslate();

  const required = computed(() =>
    (rules.value || []).some((r) => !!r?.prototype?.required)
  );

  const rulesArray = computed(() => {
    return (rules.value || []).map((r) => r(label.value));
  });

  const computedLabel = computed(() =>
    required.value ? requiredLabel(label.value) : label.value
  );

  const finalResponseName: ComputedRef<IFormFieldApiName> = computed(() =>
    responseName.value != null ? toArray(responseName.value) : undefined
  );

  function clearError() {
    error.value = undefined;
  }

  watch(value, () => clearError(), { deep: true });

  return {
    value,
    bindings: computed(() => {
      let additionalPropsToSet: {
        error?: boolean;
        errorMessages?: string[];
      } = {};

      if (error.value) {
        additionalPropsToSet["error"] = true;

        if (error.value.code) {
          additionalPropsToSet["errorMessages"] = [
            t(error.value.code, { field: label.value }),
          ];
        }
      }

      if (additionalProps.value) {
        additionalPropsToSet = {
          ...additionalPropsToSet,
          ...additionalProps.value,
        };
      }

      return {
        label: computedLabel.value,
        rawLabel: label.value,
        rules: rulesArray.value,
        ...additionalPropsToSet,
      };
    }),
    setError(apiError: IAPIErrorDetails) {
      error.value = apiError;
    },
    additionalProps: additionalProps as Ref<TProps | undefined>,
    clearError,
    responseName: finalResponseName,
  };
}

function matchesName(haystack: IFormFieldApiName, needle: string) {
  return toArray(haystack)?.some((s) => {
    if (!s) {
      return false;
    }

    if (isString(s)) {
      return needle === s;
    } else {
      return s.test(needle);
    }
  });
}

export type IMaybeReactiveForm<TFormModel> =
  | IForm<TFormModel>
  | IFormReactive<TFormModel>;

/**
 * Extracts current values from a form object, unwrapping refs if necessary.
 * Constructs a plain object with the same shape and types as the TFormModel.
 * @param form
 */
export function getFormValues<TFormModel>(
  form: IMaybeReactiveForm<TFormModel>
): TFormModel {
  const values = {} as TFormModel;

  for (const key in form) {
    const prop = key as keyof TFormModel;
    const field = form[prop];
    values[prop] = unref(field.value) as TFormModel[keyof TFormModel];
  }

  return values;
}

export function assignFormValues<TFormModel extends object>(
  form: IMaybeReactiveForm<TFormModel>,
  values: TFormModel
) {
  const keys = Object.keys(values) as (keyof TFormModel)[];

  for (const prop of keys) {
    if (isRef(form[prop].value)) {
      (form[prop].value as Ref).value = values[prop];
    } else {
      form[prop].value = values[prop];
    }
  }
}

export type IFormChangeType = "added" | "removed" | "changed" | "same";
export type IFormChange<T> = {
  change: IFormChangeType;
  changed: boolean;
  initialValue: T;
  currentValue: T;
};

export type IGetFormChangesReturnType<TFormModel> = {
  [key in keyof TFormModel]: IFormChange<TFormModel[key]>;
};

export function getFormChanges<TFormModel extends object>(
  initial: TFormModel,
  compareTo: TFormModel
): IGetFormChangesReturnType<TFormModel> {
  const keys = Object.keys(initial) as (keyof TFormModel)[];

  const changes: Partial<IGetFormChangesReturnType<TFormModel>> = {};

  keys.forEach((key) => {
    const initialValue = initial[key];
    const currentValue = compareTo[key];
    let change: IFormChangeType = "same";

    if (initialValue == null && currentValue != null) {
      change = "added";
    } else if (initialValue != null && currentValue == null) {
      change = "removed";
    } else if (!isEqual(initialValue, currentValue)) {
      change = "changed";
    }

    set(changes, key, {
      change,
      changed: change !== "same",
      initialValue,
      currentValue,
    });
  });

  return changes as IGetFormChangesReturnType<TFormModel>;
}

export interface IGetFieldChangesItem<
  TFormModel extends object,
  TKey = keyof TFormModel,
> {
  field: TKey;
  change: IFormChange<TKey>;
}

export function getFormFieldChanges<TFormModel extends object>(
  bookingFormValues: Maybe<TFormModel>,
  formValues: Maybe<TFormModel>,
  fieldsToCheck: (keyof TFormModel)[]
): IGetFieldChangesItem<TFormModel>[] {
  if (!bookingFormValues || !formValues) {
    return [];
  }

  const changesToOriginalBooking = getFormChanges(
    bookingFormValues,
    formValues
  );

  if (changesToOriginalBooking) {
    const keys = (
      Object.keys(changesToOriginalBooking) as (keyof TFormModel)[]
    ).filter((key) => fieldsToCheck.includes(key));

    return keys
      .map((key) => {
        const change = changesToOriginalBooking?.[key];

        if (change?.changed) {
          return {
            field: key,
            change: changesToOriginalBooking?.[key],
          };
        }
      })
      .filter((m) => !!m) as IGetFieldChangesItem<TFormModel>[];
  }

  return [];
}
