import type { IAuth, IUser } from "@/api/types/auth-types";
import { useAuthStore } from "@/store/auth-store";
import { usePermissionStore } from "@/store/permission-store";

export const maximumRefreshAttempts = 1;

class SessionService {
  getUser(): IUser | undefined {
    return useAuthStore().user || undefined;
  }

  getToken(): string | undefined {
    return useAuthStore().credentials?.access_token;
  }

  getRefreshToken(): string | undefined {
    return useAuthStore().credentials?.refresh_token;
  }

  isAuthenticated() {
    return useAuthStore().isAuthenticated;
  }

  async clearSession() {
    return Promise.all([useAuthStore().logout(), usePermissionStore().reset()]);
  }

  async setUserCredentials(data: IAuth) {
    return useAuthStore().setCredentials(data);
  }

  async setUserData(data: IUser) {
    return useAuthStore().setUserData(data);
  }

  async incrementTokenRefreshAttempts() {
    if (this.isMaximumRefreshAttemptsReached()) {
      throw new Error(
        `Maximum refresh attempts of ${maximumRefreshAttempts} reached!`
      );
    }

    useAuthStore().authAttempts = this.getTokenRefreshAttempts() + 1;
  }

  async resetRefreshAttempts() {
    useAuthStore().authAttempts = 0;
  }

  getTokenRefreshAttempts(): number {
    return useAuthStore().authAttempts;
  }

  isMaximumRefreshAttemptsReached() {
    return this.getTokenRefreshAttempts() >= maximumRefreshAttempts;
  }
}

export const sessionService = new SessionService();
