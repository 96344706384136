import { createRouter, createWebHistory, type RouteMeta } from "vue-router";

import { useManagerFeature } from "@/composables/use-manager-feature";
import { useAuthStore } from "@/store/auth-store";
import { can } from "@/utils/acl-utils";
import { getEnv } from "@/utils/app-utils";
import { toArray } from "@/utils/array-utils";

import { routeNames } from "./route-names";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export const defaultLandingPage = routeNames.tasksOverview;

// handle authorization and permissions
router.beforeEach(async (to, _, next) => {
  // logged-in users should not visit login page
  const isAuthenticated = useAuthStore().isAuthenticated;
  const { isFeatureDisabled } = useManagerFeature();

  // Merges all meta definitions from all matched routes into one.
  // The most inner child overrules the parent definitions.
  const finalMeta: RouteMeta | undefined = to.matched.reduce(
    (meta, current) => {
      return {
        ...meta,
        ...current.meta,
      };
    },
    to.meta
  );

  if (finalMeta?.env) {
    const envs = toArray(finalMeta.env);

    if (!envs.includes(getEnv())) {
      return next({
        name: routeNames.forbidden,
      });
    }
  }

  if (to.fullPath === "/" && !isAuthenticated) {
    return next({
      name: routeNames.login,
    });
  }
  if (to.fullPath === "/" && isAuthenticated) {
    return next({
      name: defaultLandingPage,
    });
  }
  if (to.name === routeNames.login && isAuthenticated) {
    return next({
      name: defaultLandingPage,
    });
  } else if (to.meta && to.meta.requiresAuth) {
    // user is not authorized, forward to login page
    if (!isAuthenticated) {
      return next({
        name: routeNames.login,
      });
    } else {
      // check if user has permission to access to given route
      const isDisabled = isFeatureDisabled(to.meta?.feature);
      const canOne = to.meta?.permission
        ? toArray(to.meta.permission).some((permission) => can(permission))
        : true;

      if (to.meta && to.meta.permission && (!canOne || isDisabled)) {
        return next({
          name: routeNames.forbidden,
        });
      }
    }
  }

  next();
});

export default router;
