import { computed, ref } from "vue";

export function usePasswordField() {
  const showPassword = ref(false);

  return {
    props: computed(() => {
      return {
        type: showPassword.value ? "text" : "password",
        "append-inner-icon": showPassword.value ? "mdi-eye" : "mdi-eye-off",
        "onClick:appendInner": () => {
          showPassword.value = !showPassword.value;
        },
      };
    }),
  };
}
