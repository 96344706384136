import { defineStore } from "pinia";

import type { IAuth, IUser } from "@/api/types/auth-types";

export interface IAuthStoreModel {
  authAttempts: number;
  credentials: IAuth | null;
  user: IUser | null;
  lastRoute: string | null;
}

export const useAuthStore = defineStore("auth", {
  state: () =>
    ({
      authAttempts: 0,
      user: null,
      credentials: null,
    }) as IAuthStoreModel,
  actions: {
    async logout() {
      this.authAttempts = 0;
      this.credentials = null;
      this.user = null;
    },
    async setCredentials(payload: IAuth) {
      if (payload.access_token && payload.expires_in > 0) {
        this.credentials = payload;
      } else {
        return this.logout();
      }
    },
    async setUserData(user: IUser) {
      this.user = user;
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.credentials && !!state.user,
    currentTenant: (state) => state.user?.tenant_name,
    userId: (state) => state.user?.id,
  },
  persist: true,
});
