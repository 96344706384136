import { captureException } from "@sentry/vue";
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";
import mem from "mem";

import { sessionService } from "@/api/services/session-service";
import type { IAuth } from "@/api/types/auth-types";
import type {
  IErrorResponse,
  IOAuthErrorResponse,
} from "@/api/types/general-types";
import { useNotifications } from "@/modules/snackbar/composables/use-notifications";
import { getPersistedLanguage } from "@/utils/user-settings-utils";

const status = {
  unauthorized: 401,
};

export function isAxiosError(e: unknown): e is AxiosError {
  const error = e as AxiosError;
  return error.isAxiosError;
}

export function isErrorResponse(e: unknown): e is AxiosError<IErrorResponse> {
  return (
    isAxiosError(e) &&
    (e.response?.data as IErrorResponse)?.error?.details != null
  );
}

export function isOAuthError(e: unknown): e is AxiosError<IOAuthErrorResponse> {
  return (
    isErrorResponse(e) &&
    (e.response?.data as IOAuthErrorResponse)?.error?.code ===
      "general.unauthorized"
  );
}

export function requestInterceptor<T extends AxiosRequestConfig>(config: T) {
  if (config.baseURL === import.meta.env.VITE_APP_API_URL) {
    const token = sessionService.getToken();

    if (token) {
      config.headers = {
        ...config.headers,
        "Content-Language": getPersistedLanguage(),
        authorization: `Bearer ${token}`,
      };
    }
  }

  return config;
}

export async function responseSuccessInterceptor(response: AxiosResponse) {
  return response.data;
}

export function refreshToken(axiosClient: AxiosInstance): Promise<unknown> {
  if (sessionService.getRefreshToken()) {
    return sessionService
      .incrementTokenRefreshAttempts()
      .then(() => {
        return axiosClient.post<IAuth>("/oauth/token", {
          grant_type: "refresh_token",
          refresh_token: sessionService.getRefreshToken(),
          scope: "*",
          client_id: import.meta.env.VITE_APP_CLIENT_ID,
          client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
        });
      })
      .then((response) => {
        return sessionService.setUserCredentials(response as unknown as IAuth);
      })
      .catch(() => {
        return performLogout();
      });
  } else {
    return performLogout();
  }
}

async function performLogout() {
  await sessionService.clearSession();
}

export const defaultHeaders = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

export const sharedConfig = {
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: defaultHeaders,
};

const maxAge = 5000;
export const memoizedRefreshToken = mem(refreshToken, {
  maxAge,
});

async function errorInterceptor(error: AxiosError, showSnackbar = false) {
  const config = error.config as InternalAxiosRequestConfig & {
    sent?: boolean;
  };

  const url: string = error.request.responseURL || "";

  if (url.endsWith("/oauth/token")) {
    // skipping error interceptor for login/refresh
    throw error;
  }

  if (
    isAxiosError(error) &&
    error.response?.status === status.unauthorized &&
    !config?.sent
  ) {
    config.sent = true;
    await memoizedRefreshToken(httpClient);
    return httpClient(requestInterceptor(config));
  } else {
    if (isErrorResponse(error)) {
      if (
        isOAuthError(error) &&
        (error.response?.data?.error?.details?.error_message ===
          "The refresh token is invalid." ||
          error.response?.status === status.unauthorized)
      ) {
        return await performLogout();
      } else {
        captureException(error);
      }
    }

    if (showSnackbar) {
      const { showErrorResponse } = useNotifications();
      showErrorResponse(error);
    }

    throw error;
  }
}

export const httpClient = axios.create(sharedConfig);
httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(responseSuccessInterceptor, (error) =>
  errorInterceptor(error, true)
);

export default httpClient;

/**
 * Does not post any snackbars
 */
export const silentHttpClient = axios.create(sharedConfig);
silentHttpClient.interceptors.request.use(requestInterceptor);
silentHttpClient.interceptors.response.use(
  responseSuccessInterceptor,
  (error) => errorInterceptor(error, false)
);
