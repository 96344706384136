import type { AxiosInstance } from "axios";

import httpClient from "@/api/http-client";
import type {
  IAuth,
  IBehalfAuth,
  ILoginPayload,
  IUser,
} from "@/api/types/auth-types";
import type { IResponse } from "@/api/types/general-types";
import { apiVersion } from "@/config/constants";
import { fallbackLocale } from "@/config/locale-config";
import { i18n, type ILanguages } from "@/plugins/i18n-plugin";
import { usePermissionStore } from "@/store/permission-store";
import type { IPermission } from "@/types/permission-types";

import { sessionService } from "./session-service";

export class AuthService {
  // requests will be performed on this entity
  protected entity: string;
  // api version
  protected version: string;
  // base path to the entity
  protected basePath: string;

  protected httpClient: AxiosInstance;

  constructor(entity: string, version?: string) {
    this.entity = entity;
    this.version = version || apiVersion;
    this.basePath = `/${this.version}/${this.entity}`;
    this.httpClient = httpClient;
  }

  login(credentials: ILoginPayload): Promise<void> {
    return (
      this.httpClient.post(`${import.meta.env.VITE_APP_API_URL}/oauth/token`, {
        grant_type: "password",
        username: credentials.email,
        password: credentials.password,
        scope: "*",
        client_id: import.meta.env.VITE_APP_CLIENT_ID,
        client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
      }) as Promise<IAuth>
    )
      .then((data) => {
        return sessionService
          .setUserCredentials(data)
          .then(() => {
            return sessionService.resetRefreshAttempts();
          })
          .then(() => {
            return this.updateClientData();
          });
      })
      .then(() => {
        // login done
        return undefined;
      });
  }

  adminLogin(email: string): Promise<void> {
    return this.httpClient
      .post<IBehalfAuth>(
        `${import.meta.env.VITE_APP_API_URL}/${this.version}/admin/login`,
        {
          email: email,
        }
      )
      .then((data) => {
        const expiresIn = Math.round(
          (new Date(data.data.token.expires_at).getTime() - Date.now()) / 1000
        );

        return sessionService
          .setUserCredentials({
            expires_in: expiresIn,
            access_token: data.data.accessToken,
            refresh_token: "",
            token_type: "Bearer",
          })
          .then(() => {
            return sessionService.resetRefreshAttempts();
          })
          .then(() => {
            return this.updateClientData();
          });
      })
      .then(() => {
        // login done
        return undefined;
      });
  }

  async logout() {
    await this.httpClient.post(this.version + "/logout").catch(() => {
      // can be ignored
    });
    await sessionService.clearSession();
  }

  fetchPermissions(): Promise<IResponse<{ name: IPermission }[]>> {
    return this.httpClient.get(
      `${import.meta.env.VITE_APP_API_URL}/${this.version}/permission/user`
    );
  }

  fetchUser(): Promise<IResponse<IUser>> {
    return this.httpClient.get(`${this.basePath}/user`);
  }

  updateClientData() {
    // enqueue any operation that should happen directly after the login
    return Promise.all([
      this.fetchUser().then((user) => {
        const locale: ILanguages =
          (user.data.language_id as ILanguages) || fallbackLocale;
        i18n.global.locale = locale.toLowerCase() as ILanguages;
        return sessionService.setUserData(user.data);
      }),
      this.fetchPermissions().then((permissions) => {
        const permissionStore = usePermissionStore();
        permissionStore.permissions = (permissions.data || []).map(
          (p) => p.name
        );
      }),
    ]);
  }

  async initializeSession() {
    const token = sessionService.getToken();

    if (!token) {
      return;
    } else {
      return this.updateClientData().catch(() => {
        return;
      });
    }
  }
}

export const authService = new AuthService("auth");
