import { groupBy, isArray, isNumber, isObject, isString } from "lodash-es";
import { type MaybeRefOrGetter, type Ref, toRef } from "vue";
import type { TranslateResult } from "vue-i18n";

import { discountService } from "@/api/services/discount-service";
import type { IDiscountCodeFnOptions } from "@/modules/discount/discount-types";
import type { IEmailEditorResult } from "@/modules/email-editor/email-editor-types";
import { i18n } from "@/plugins/i18n-plugin";
import type { IBookingPlanTableItem } from "@/types/booking-plan-types";
import type { IAllowedFileTypes } from "@/types/file-upload-types";
import type { IPhoneNumber } from "@/types/ui-phone-number";
import type { Maybe } from "@/types/utility-types";
import { toArray } from "@/utils/array-utils";
import { isEditorEmpty } from "@/utils/email-editor-utils";
import { getTranslatedApiErrorMessages } from "@/utils/error-utils";

export type ValidationFnResult = string | TranslateResult | boolean;

export type ValidationFnFactory = (
  name: string | TranslateResult
) => ValidationFn;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationFn = (value: any) => ValidationFnResult;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncValidationFn = (value: any) => Promise<ValidationFnResult>;

export function positiveDecimalFn(
  field: string | TranslateResult,
  decimalPlaces = 2,
  minVal = 0
): ValidationFn {
  const min = minFn(field, minVal);
  const format = maxDecimalPlacesFn(field, decimalPlaces);
  const fns = [min, format];

  return (value: string | number) => {
    for (const fn of fns) {
      const result = fn(value);
      if (result !== true) {
        return result;
      }
    }

    return true;
  };
}

export function requiredFn(field: string | TranslateResult): ValidationFn {
  return (value: string | number) => {
    if (typeof value === "string" && value.trim() === "") {
      return i18n.global.t("validation.required", { field });
    }

    if (value === "0") {
      value = 0 as number;
    }

    return (
      (isNumber(value) && value > -1) ||
      !!value ||
      i18n.global.t("validation.required", { field })
    );
  };
}

requiredFn.prototype.required = true;

export function requiredUniqueFn(
  field: string | TranslateResult,
  forbiddenValues: Ref<string[]>
): ValidationFn {
  return (value: string) => {
    const v = value?.trim().toLowerCase();
    const hit = (forbiddenValues.value || []).find(
      (template) => template.toLowerCase() === v
    );

    if (hit) {
      return i18n.global.t("validation.duplicateFieldValue", { field });
    } else {
      return true;
    }
  };
}

export function requiredBillingPeriodsFn(
  field: string | TranslateResult
): ValidationFn {
  return (value: Maybe<IBookingPlanTableItem[]>) => {
    return (
      (value?.length || 0) > 0 ||
      i18n.global.t("validation.required", { field })
    );
  };
}

requiredBillingPeriodsFn.prototype.required = true;

export function billingPeriodsUniqueFn(
  field: string | TranslateResult
): ValidationFn {
  return (value: Maybe<IBookingPlanTableItem[]>) => {
    if (!value?.length) {
      return true;
    }

    const grouped = groupBy(
      value || [],
      // billing periods are unique by amount, period and optionally the name.
      (item) => `${item.amount}_${item.period}_${item.name}`
    );

    if (Object.values(grouped).some((items) => items.length > 1)) {
      return i18n.global.t("validation.some_duplicate_booking_plans", {
        field,
      });
    }

    return true;
  };
}

export function requiredListFn(field: string | TranslateResult): ValidationFn {
  return (value: unknown[]) => {
    return (
      (value && value.length > 0) ||
      i18n.global.t("validation.required", { field })
    );
  };
}

requiredListFn.prototype.required = true;

const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export function emailFn(field: string | TranslateResult): ValidationFn {
  return (value: unknown[]) => {
    if (value && isString(value)) {
      const email = value.toLowerCase().trim();
      return (
        emailRegex.test(email) || i18n.global.t("validation.email", { field })
      );
    }
    return true;
  };
}

export function emailListFn(field: string | TranslateResult): ValidationFn {
  return (value: unknown[] | unknown) => {
    const values: string[] = toArray(value as string[]);

    if (
      !values.every((email) => (email != null ? emailRegex.test(email) : true))
    ) {
      return i18n.global.t("validation.email", { field });
    }

    return true;
  };
}

export function googleAnalyticsKeyFn(
  field: string | TranslateResult
): ValidationFn {
  return (value: string) => {
    if (value && isString(value)) {
      const text = value.toLowerCase().trim();
      return (
        /^g-[a-z0-9-]+$/i.test(text) ||
        i18n.global.t("validation.googleAnalyticsKey", { field })
      );
    }
    return true;
  };
}

export function googleTagManagerApiKeyFn(
  field: string | TranslateResult
): ValidationFn {
  return (value: string) => {
    if (value && isString(value)) {
      const text = value.toLowerCase().trim();
      return (
        /^GTM-[A-Za-z0-9]+$/i.test(text) ||
        i18n.global.t("validation.googleTagManagerApiKey", { field })
      );
    }
    return true;
  };
}

export function domainFn(field: string | TranslateResult): ValidationFn {
  return (value: unknown[]) => {
    if (value && isString(value)) {
      const domain = value.toLowerCase().trim();
      return (
        /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
          domain
        ) || i18n.global.t("validation.domain", { field })
      );
    }
    return true;
  };
}

export function subdomainFn(field: string | TranslateResult): ValidationFn {
  return (value: unknown[]) => {
    if (value && isString(value)) {
      const domain = value.toLowerCase().trim();
      return (
        /^[a-z0-9][a-z0-9-]*[a-z0-9]$/.test(domain) ||
        i18n.global.t("validation.subdomain_invalid", { field })
      );
    }
    return true;
  };
}

export function validateHexColor(hexColorString?: string | null): boolean {
  return /^#([A-F0-9]{6}|[A-F0-9]{3})$/.test(
    (hexColorString || "").toUpperCase()
  );
}

export function hexColorFn(field: string | TranslateResult): ValidationFn {
  return (value: string) => {
    if (value && isString(value)) {
      const trimmed = value.toUpperCase().trim();
      return (
        validateHexColor(trimmed) ||
        i18n.global.t("validation.hexColor", { field })
      );
    }

    return true;
  };
}

export function urlFn(field: string | TranslateResult): ValidationFn {
  return (value: unknown[]) => {
    if (value && isString(value)) {
      let url = null;

      try {
        url = new URL(value);
      } catch (_) {
        return i18n.global.t("validation.url", { field });
      }

      return url.protocol === "http:" || url.protocol === "https:"
        ? true
        : i18n.global.t("validation.url", { field });
    }
    return true;
  };
}

export function discountCodeFn(
  field: string | TranslateResult,
  givenOptions: MaybeRefOrGetter<IDiscountCodeFnOptions>
): AsyncValidationFn {
  return (value: string) => {
    const val = value || "";
    const options = toRef(givenOptions);

    if (
      val.length > 0 &&
      options.value.locationId != null &&
      ((options.value.productIds || []).length > 0 ||
        (options.value.billingPeriodIds || []).length > 0)
    ) {
      return discountService
        .validateCustomerFacingCode(
          val,
          options.value.locationId,
          options.value.discountCodeId,
          options.value.billingPeriodIds,
          options.value.productIds
        )
        .then(() => true)
        .catch((e) => getTranslatedApiErrorMessages(e)?.[0]);
    }

    return Promise.resolve(true);
  };
}

export function positiveIntegerFn(
  field: string | TranslateResult
): ValidationFn {
  return (value: unknown) => {
    const str = String(value);
    return (
      /^[0-9]+$/.test(str) ||
      i18n.global.t("validation.positiveInteger", { field })
    );
  };
}

export function minFn(
  field: string | TranslateResult,
  min: MaybeRefOrGetter<number>
): ValidationFn {
  return (value: unknown) => {
    const minRef = toRef(min);
    // TODO add valid locale based number parsing here!
    const val = parseFloat(`${value}`);

    if (isNaN(val)) {
      return true;
    }

    if (val < minRef.value) {
      return i18n.global.t("validation.min", {
        field,
        min: minRef.value,
        current: val,
      });
    }

    return true;
  };
}

export function maxFn(
  field: string | TranslateResult,
  max: MaybeRefOrGetter<number>
): ValidationFn {
  return (value: unknown) => {
    const maxRef = toRef(max);
    // TODO add valid locale based number parsing here!
    const val = parseFloat(`${value}`);

    if (isNaN(val)) {
      return true;
    }

    if (val > maxRef.value) {
      return i18n.global.t("validation.max.generic", {
        field,
        max: maxRef.value,
        current: val,
      });
    }

    return true;
  };
}

export function maxLenFn(
  field: string | TranslateResult,
  maxLen: number
): ValidationFn {
  return (value: string) => {
    if ((value || "").length > maxLen) {
      return i18n.global.t("validation.max.length", {
        field,
        max: maxLen,
        current: value,
      });
    }

    return true;
  };
}

export function requiredEmailTemplateFn(
  field: string | TranslateResult
): ValidationFn {
  return (value: IEmailEditorResult | undefined) => {
    if (!isEditorEmpty(value)) {
      return true;
    } else {
      return i18n.global.t("validation.required", { field });
    }
  };
}

requiredEmailTemplateFn.prototype.required = true;

export function maxDecimalPlacesFn(
  field: string | TranslateResult,
  decimalPlaces = 2
): ValidationFn {
  return (value: number) => {
    // TODO add valid locale based number parsing here!
    const v = +value;
    const formattedValue = v?.toFixed(decimalPlaces);
    if (v !== parseFloat(formattedValue)) {
      return i18n.global.t("validation.max.decimalPlaces", {
        field,
        places: decimalPlaces,
        current: value,
      });
    }
    return true;
  };
}

export function phoneNumberFn(field: string | TranslateResult) {
  return (value: undefined | null | IPhoneNumber) => {
    if (!isObject(value)) {
      return true;
    }

    const trimmed = (value?.number || "").trim();

    // Skip check if no phone number was entered
    if (!trimmed) {
      return true;
    }

    // Phone number needs to be at least 4 chars long
    if (trimmed.length < 3 || !/^[0-9]+$/.test(trimmed)) {
      return i18n.global.t("validation.phone", {
        field,
        current: value,
      });
      // If number is valid check if a prefix is available
    } else if (!value?.prefix) {
      return i18n.global.t("validation.phone_prefix", {
        field,
        current: value,
      });
    }

    return true;
  };
}

export function passwordFn(field: string | TranslateResult) {
  return (value: string) => {
    // Must have at least one lowercase & at least one uppercase letter
    const rules = [
      {
        check: (value: string | TranslateResult) => (value || "").length >= 8,
        msg: i18n.global.t("validation.password.min", {
          field,
          min: 8,
          current: (value || "").length,
        }),
      },
      {
        check: (value: string | TranslateResult) => (value || "").length <= 255,
        msg: i18n.global.t("validation.password.max", {
          field,
          max: 255,
          current: (value || "").length,
        }),
      },
      {
        check: (value: string | TranslateResult) =>
          /[0-9]/.test((value || "") as string),
        msg: i18n.global.t("validation.password.numberRequired", { field }),
      },
      {
        check: (value: string | TranslateResult) =>
          /[a-züöäß]/.test((value || "") as string),
        msg: i18n.global.t("validation.password.lowerCaseLetterRequired", {
          field,
        }),
      },
      {
        check: (value: string | TranslateResult) =>
          /[A-ZÖÜÄ]/.test((value || "") as string),
        msg: i18n.global.t("validation.password.upperCaseLetterRequired", {
          field,
        }),
      },
    ];

    const messages: string[] = [];
    for (const rule of rules) {
      if (!rule.check(value)) {
        messages.push(rule.msg);
      }
    }

    if (messages.length) {
      return messages.join("\n");
    }

    return true;
  };
}

passwordFn.prototype.required = true;

export function passwordMatchFn(password: string, passwordConfirm: string) {
  return (
    password === passwordConfirm || i18n.global.t("validation.password.match")
  );
}

export function emailArrayFn(field: string | TranslateResult) {
  return (value: string | string[]) => {
    if (!value || !isArray(value) || value.length <= 0) {
      return true;
    }

    for (const email of value) {
      const returnValue = emailFn(field)(email);
      if (returnValue !== true) {
        return returnValue;
      }
    }

    return true;
  };
}

export function allowedFileExtensionsFn(
  field: string | TranslateResult,
  allowedExtensions: IAllowedFileTypes
): ValidationFn {
  return (value: File) => {
    const isAllowed = allowedExtensions.some((extension) =>
      value.type.includes(extension)
    );

    return (
      isAllowed ||
      i18n.global.t("validation.invalidFileType", {
        fileTypes: allowedExtensions.join(", ").toUpperCase(),
      })
    );
  };
}

export function maxFileSizeFn(
  field: string | TranslateResult,
  maxFileSize: number
): ValidationFn {
  return (value: File) => {
    return (
      value.size <= maxFileSize ||
      i18n.global.t("validation.maxFileSize", {
        field,
        max: maxFileSize,
      })
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validate(value: any, rules: ValidationFn[] = []) {
  return (rules || [])
    .map((rule) => {
      return rule(value);
    })
    .filter((result) => isString(result));
}

export function requiredLabel(msg: string | TranslateResult): string {
  return `${msg} *`;
}
