import { captureException } from "@sentry/vue";
import { defineStore } from "pinia";
import { computed, ref, watchEffect } from "vue";

import { useSetupQuery } from "@/api/queries/use-setup-query";
import type { ISetupResponse } from "@/api/types/setup-types";

export const useSetupStore = defineStore("setup", () => {
  const { data, isLoading, isError, error } = useSetupQuery();

  const setupData = ref<ISetupResponse>();

  watchEffect(() => {
    if (data.value && !isError.value) {
      setupData.value = data.value.data;
    } else if (error.value) {
      captureException(error.value);
    }
  });

  return {
    availableLanguages: computed(
      () => setupData.value?.available_languages || []
    ),
    managerLanguages: computed(() => setupData.value?.manager_languages || []),
    isLoading,
  };
});
