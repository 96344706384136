import BaseProxy from "@/api/base-proxy";
import { silentHttpClient } from "@/api/http-client";
import type {
  ICreateDiscountPayload,
  IDiscountDetails,
  IDiscountListItem,
  IDiscountListItemResponse,
  IDiscountNameValidationPayload,
  IDiscountUpdatePayload,
  IListDiscount,
} from "@/api/types/discount-types";

class DiscountService extends BaseProxy<
  IDiscountListItemResponse,
  IDiscountDetails,
  ICreateDiscountPayload,
  IDiscountListItem,
  IDiscountUpdatePayload,
  IDiscountListItem
> {
  constructor() {
    super("discount");
  }

  validateName({
    value,
    discount_id,
    location_id,
  }: IDiscountNameValidationPayload): Promise<boolean> {
    if (!value || location_id == null) {
      return Promise.resolve(true);
    }

    return silentHttpClient
      .post(`${this.basePath}/validation/name`, {
        value,
        location_id,
        discount_id,
      })
      .then(() => true)
      .catch(() => false);
  }

  validateCustomerFacingCode(
    value?: string,
    locationId?: number,
    discountId?: number,
    billingPeriodIds?: number[],
    productIds?: number[]
  ): Promise<boolean> {
    if (!value || locationId == null) {
      return Promise.resolve(true);
    }

    return this.silentHttpClient
      .post(`${this.basePath}/validation/code`, {
        value,
        location_id: locationId,
        discount_id: discountId,
        billing_period_ids: billingPeriodIds,
        product_ids: productIds,
      })
      .then(() => true);
  }

  getAllFromLocation(locationId: number) {
    return this.httpClient.get<IListDiscount>(
      `${this.basePath}?filter[location]=${locationId}`
    );
  }
}

export const discountService = new DiscountService();
