import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesConnectedApps: RouteMetaConfig[] = [
  {
    path: "/connected-apps",
    name: routeNames.connectedApps,
    component: () => import("./ConnectedAppsListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "connected_app_connections.view",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.connectedAppsSensorberg,
          path: "sensorberg",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsSensorbergView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSCSolutions,
          path: "sc-solutions",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsScSolutionView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsNoke,
          path: "noke",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsNokeView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSedisto,
          path: "sedisto",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsSedistoView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSignable,
          path: "signable",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsSignableView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsSignableHowToSetup,
          path: "signable-how-to-setup",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsSignableHowToSetup.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsStripeIdCheck,
          path: "id-check",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          component: () => import("./ConnectedAppsStripeIdCheckView.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          name: routeNames.connectedAppsOpenTech,
          path: "open-tech/:id",
          meta: {
            requiresAuth: true,
            permission: "connected_app_connections.create",
          },
          props: true,
          component: () => import("./ConnectedAppsOpenTechView.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];
