<template>
  <div>
    <UIMenu>
      <template #activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          icon
          size="x-large"
          data-test-id="user-avatar-toggle-button"
        >
          <UIAvatar data-test-id="user-avatar">{{ initials }}</UIAvatar>
        </v-btn>
      </template>
      <UICard elevation="none">
        <div class="flex flex-col justify-center items-center text-center p-4">
          <div class="text-2xl">
            <UIAvatar data-test-id="user-avatar">{{ initials }}</UIAvatar>
          </div>
          <h3 class="mt-2 font-bold text-lg" data-test-id="user-name">
            {{ fullName }}
          </h3>
          <p class="text-xs mt-1" data-test-id="user-email">
            {{ email }}
          </p>
          <v-divider class="my-3" />
          <AppLanguageSwitcher />
          <v-divider class="my-3" />
          <UIButton
            variant="clear"
            data-test-id="logout-button"
            @click="logout()"
          >
            {{ $t("logoutButton.label") }}
          </UIButton>
        </div>
      </UICard>
    </UIMenu>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";

import { authService } from "@/api/services/auth-service";
import { useIntercom } from "@/composables/use-intercom";
import { useUser } from "@/composables/use-user";
import UIAvatar from "@/modules/ui/avatars/components/UIAvatar.vue";
import UIButton from "@/modules/ui/buttons/components/UIButton.vue";
import UICard from "@/modules/ui-card/components/UICard.vue";
import UIMenu from "@/modules/ui-menu/components/UIMenu.vue";
import { routeNames } from "@/router/route-names";

import AppLanguageSwitcher from "./AppLanguageSwitcher.vue";

const { fullName, initials, email } = useUser();
const router = useRouter();
const { shutdownIntercom } = useIntercom();

async function logout() {
  await authService.logout();
  shutdownIntercom();
  router.push({ name: routeNames.login });
}
</script>
